@import "../../assets/scss/colors.scss";
@import "../../assets/scss/mixins/col.scss";

.playlist {
  display: flex;

  & p.second-line {
    font-size: 0.9rem;
  }
}
