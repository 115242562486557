.loading {
  display: flex;

  .loading-element {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
  }
  .loading-element div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 45px;
    height: 45px;
    margin: 0px;
    border: 3px solid #000;
    border-radius: 50%;
    animation: loading-element 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent;
  }
  .loading-element div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .loading-element div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loading-element div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes loading-element {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
