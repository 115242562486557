@import "../../assets//scss/colors.scss";

.modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background: rgba(0, 0, 0, 0.7);
}

.modal {
  flex-basis: 300px;
  background-color: $white;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  justify-items: center;

  .modal-content {
    input,
    select {
      padding: 10px;
      border-radius: 8px;
      border: 1px solid $black;
      width: 160px;
      margin: 10px 0px 20px;
    }
  }
  .modal-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      margin-top: 10px;
    }
  }
}
