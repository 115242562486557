@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800&amp;subset=latin-ext");

body {
  font-family: "Open Sans", Arial;
  margin: 0 10px 0 10px;
}

section {
  height: calc(100vh - 140px);
}
