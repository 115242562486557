@mixin col {
  flex: 1;
  padding: 8px 10px;

  &.col-first {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 20px;
  }

  &.col-last {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &.col-fixed {
    flex-basis: 50px;
    flex-grow: 0;
  }
}
