@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800&amp;subset=latin-ext);
body {
  font-family: "Open Sans", Arial;
  margin: 0 10px 0 10px; }

section {
  height: calc(100vh - 140px); }

section.login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  section.login .login-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    background: #fff;
    width: 300px;
    padding: 25px 15px;
    border-radius: 8px; }
    section.login .login-container .form-element {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-bottom: 15px; }
      section.login .login-container .form-element:last-of-type {
        margin-top: 5px;
        margin-bottom: 0px; }
      section.login .login-container .form-element label {
        font-weight: 600;
        font-size: 0.9rem; }
      section.login .login-container .form-element input {
        border-radius: 8px;
        border: 1px solid #ccc;
        padding: 10px 5px; }

.btn {
  width: 180px;
  height: 50px;
  border: none;
  background: #efefef;
  color: #000;
  font-weight: 600;
  cursor: pointer; }
  .btn.btn-green {
    color: #fff;
    background-color: #80cc14; }
  .btn.btn-red {
    color: #fff;
    background-color: #e27777; }
  .btn:disabled {
    color: #fff;
    background-color: #efefef;
    cursor: not-allowed; }

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px 10px; }

.search {
  padding: 15px;
  border: 1px solid #ccc; }

.header-actions .btn {
  margin: 0 10px; }
  .header-actions .btn:first-of-type {
    margin-left: 0; }
  .header-actions .btn:last-of-type {
    margin-right: 0; }

.gridHeader {
  display: -webkit-flex;
  display: flex; }
  .gridHeader .col {
    background-color: #3bb6e5;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 8px 10px; }
    .gridHeader .col.col-first {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      padding-left: 20px; }
    .gridHeader .col.col-last {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px; }
    .gridHeader .col.col-fixed {
      -webkit-flex-basis: 50px;
              flex-basis: 50px;
      -webkit-flex-grow: 0;
              flex-grow: 0; }

.loading-row {
  width: 22px !important;
  height: 22px !important;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  display: none; }

.loading-row div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 18px !important;
  height: 18px !important;
  margin: 0px;
  border: 3px solid #000;
  border-radius: 50%;
  -webkit-animation: loading-row 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: loading-row 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent; }

.loading-row div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s; }

.loading-row div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }

.loading-row div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s; }

@-webkit-keyframes loading-row {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loading-row {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.row {
  display: -webkit-flex;
  display: flex; }
  .row :first-child {
    -webkit-flex-grow: 1;
            flex-grow: 1; }
  .row .col {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 8px 10px;
    background-color: #efefef;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .row .col.col-first {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      padding-left: 20px; }
    .row .col.col-last {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px; }
    .row .col.col-fixed {
      -webkit-flex-basis: 50px;
              flex-basis: 50px;
      -webkit-flex-grow: 0;
              flex-grow: 0; }
    .row .col.col-composed {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: flex-start;
              align-items: flex-start; }
      .row .col.col-composed p {
        margin: 0;
        -webkit-flex-grow: 1;
                flex-grow: 1; }
    .row .col.col-fixed {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center; }
      .row .col.col-fixed div, .row .col.col-fixed span {
        width: 30px;
        height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto auto;
        background-size: initial;
        cursor: pointer; }
      .row .col.col-fixed .col-delete {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAA30lEQVQ4T72UURHCQAxEXxWAE5AADpAATkABEqgDcAASwAk4YJa5MiEk1492en/Npe82t7k0jLyakXlMAtwCR2AN3JMKlsAZOACtzfEKBTuVhGcCFewKzEvezkI9UIoW5kQP9TClPgDFP8sDtXEDZgFUIatM3y9gZa8mMiWDCtCVGcIihZ2wCGrv/k9ZVrL9KYOmsJpC7UUGKJ65H5piS/YGWPUpNDMlclPAyP2f5o/aJmsNAbOW+kL7GtsbEBlVbWz79DI3PbT69FSWoHtg0zMcLiWvOhwGj8dJ5uEglW+6aDoVnSwy+gAAAABJRU5ErkJggg==); }
        .row .col.col-fixed .col-delete:hover {
          background-image: url(/build/static/media/ico_delete_hover.10e7259f.png); }
      .row .col.col-fixed .col-play {
        background-image: url(/build/static/media/ico_play.e1c09981.svg);
        background-size: contain;
        position: relative; }
        .row .col.col-fixed .col-play.playing {
          background-image: url(/build/static/media/ico_pause.449c3978.svg); }
          .row .col.col-fixed .col-play.playing.loading {
            background-image: none; }
            .row .col.col-fixed .col-play.playing.loading .loading-row {
              display: block; }
      .row .col.col-fixed .col-select {
        background-image: url(/build/static/media/ico_select.80ccda0c.png); }
        .row .col.col-fixed .col-select:hover {
          background-image: url(/build/static/media/ico_select_hover.cb907b9d.png); }
        .row .col.col-fixed .col-select.selected {
          background-image: url(/build/static/media/ico_select_selected.46e280ac.png); }
      .row .col.col-fixed .col-edit {
        background-image: url(/build/static/media/ico_edit.d0e7bc49.png); }
        .row .col.col-fixed .col-edit:hover {
          background-image: url(/build/static/media/ico_edit_hover.a577feb4.png); }
      .row .col.col-fixed .col-details {
        background-image: url(/build/static/media/ico_details.d8de1812.png); }
        .row .col.col-fixed .col-details:hover {
          background-image: url(/build/static/media/ico_details_hover.0e268df0.png); }
      .row .col.col-fixed .col-duplicate {
        background-image: url(/build/static/media/ico_duplicate.047bc189.png); }
        .row .col.col-fixed .col-duplicate:hover {
          background-image: url(/build/static/media/ico_duplicate_hover.b3cfb85e.png); }
  .row:nth-of-type(2n + 1) .col {
    background-color: #fff; }

.playlist {
  display: -webkit-flex;
  display: flex; }
  .playlist p.second-line {
    font-size: 0.9rem; }

.modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  z-index: 3;
  background: rgba(0, 0, 0, 0.7); }

.modal {
  -webkit-flex-basis: 300px;
          flex-basis: 300px;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  justify-items: center; }
  .modal .modal-content input,
  .modal .modal-content select {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #000;
    width: 160px;
    margin: 10px 0px 20px; }
  .modal .modal-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
    .modal .modal-buttons .btn {
      margin-top: 10px; }

.loading {
  display: -webkit-flex;
  display: flex; }
  .loading .loading-element {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto; }
  .loading .loading-element div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 45px;
    height: 45px;
    margin: 0px;
    border: 3px solid #000;
    border-radius: 50%;
    -webkit-animation: loading-element 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: loading-element 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000 transparent transparent transparent; }
  .loading .loading-element div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s; }
  .loading .loading-element div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s; }
  .loading .loading-element div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s; }

@-webkit-keyframes loading-element {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loading-element {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.row-container .row .col {
  background-color: #efefef; }

.row-container:nth-of-type(2n + 1) .row .col {
  background-color: #fff; }

.playlistLength {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 0px;
  padding-right: 15px; }

