@import "../../assets/scss/colors.scss";
@import "../../assets/scss/mixins/col.scss";

.gridHeader {
  display: flex;

  .col {
    background-color: $blue-nuvelar;
    color: $white;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    @include col;
  }
}
