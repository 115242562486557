@import "../../assets/scss/colors.scss";
@import "../../assets/scss/mixins/col.scss";

.row {
  display: flex;

  & :first-child {
    flex-grow: 1;
  }

  .col {
    @include col;

    background-color: $grey;
    display: flex;
    align-items: center;

    &.col-composed {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      p {
        margin: 0;
        flex-grow: 1;
      }
    }

    &.col-fixed {
      display: flex;
      justify-content: center;

      div, span {
        width: 30px;
        height: 30px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: initial;
        cursor: pointer;
      }

      .col-delete {
        background-image: url(../../assets/img/ico_delete.png);

        &:hover {
          background-image: url(../../assets/img/ico_delete_hover.png);
        }
      }

      .col-play {
        background-image: url(../../assets/img/ico_play.svg);
        background-size: contain;
        position: relative;

        &.playing {
          background-image: url(../../assets/img/ico_pause.svg);

          &.loading {
            background-image: none;

            .loading-row {
              display: block;
            }
          }
        }
      }

      .col-select {
        background-image: url(../../assets/img/ico_select.png);

        &:hover {
          background-image: url(../../assets/img/ico_select_hover.png);
        }

        &.selected {
          background-image: url(../../assets/img/ico_select_selected.png);
        }
      }

      .col-edit {
        background-image: url(../../assets/img/ico_edit.png);

        &:hover {
          background-image: url(../../assets/img/ico_edit_hover.png);
        }
      }

      .col-details {
        background-image: url(../../assets/img/ico_details.png);

        &:hover {
          background-image: url(../../assets/img/ico_details_hover.png);
        }
      }

      .col-duplicate {
        background-image: url(../../assets/img/ico_duplicate.png);

        &:hover {
          background-image: url(../../assets/img/ico_duplicate_hover.png);
        }
      }
    }
  }

  &:nth-of-type(2n + 1) {
    .col {
      background-color: $white;
    }
  }
}
