@import "../../assets/scss/colors.scss";

.btn {
  width: 180px;
  height: 50px;
  border: none;
  background: $grey;
  color: $black;
  font-weight: 600;
  cursor: pointer;

  &.btn-green {
    color: $white;
    background-color: $green-nuvelar;
  }

  &.btn-red {
    color: $white;
    background-color: $red-nuvelar;
  }

  &:disabled {
    color: $white;
    background-color: $grey;
    cursor: not-allowed;
  }
}
