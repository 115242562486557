@import "../../assets/scss/colors.scss";

.row-container {
  .row {
    .col {
      background-color: $grey;
    }
  }

  &:nth-of-type(2n + 1) {
    .row {
      .col {
        background-color: $white;
      }
    }
  }
}

.playlistLength {
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
  padding-right: 15px;
}